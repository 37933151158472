import React from 'react';
import Tab from '../tabs/Tab';
import Tabs from '../tabs/Tabs';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import CheckedLottie from "../../images/CheckedLottie.json"
import { Player } from "@lottiefiles/react-lottie-player";

import './css/download.css';
import LeftMobImage from "../../images/download-left-mobile-1.svg"
import LeftDesktopImage from "../../images/download-left-desktop-1.svg"
import CheckedSvg from '../../images/checked.svg';
import PlayStoreImage from '../../images/playstore.png';
import AppStoreImage from '../../images/appstore.png';
import QrCodeImage from '../../images/qrcode.png';

import { logEvent } from '../../core/analytics';
import { useMixpanel } from 'gatsby-plugin-mixpanel';

import {
  BrowserView,
  MobileView,
} from "react-device-detect";


function DownloadComponent({ sendSmsFunc, sendEmailFunc, countryPhoneCode, countryCode, isVerified, isB2c }) {

  const [mobile, setMobile] = React.useState(null);
  const [code, setCode] = React.useState(countryPhoneCode);
  const [email, setEmail] = React.useState(null);
  const [canSendSms, setCanSendSms] = React.useState(false);
  const [canSendEmail, setCanSendEmail] = React.useState(false);

  const mixpanel = useMixpanel()
  
  const sendSmsFuncHandle = () => {
    sendSmsFunc(`${code}${mobile}`);
    logEvent("b2b_landing_page_send_sms", {"mobile": `${code}${mobile}`}, mixpanel)
  }

  const sendEmailFuncHandle = () => {
    sendEmailFunc(email);
    logEvent("b2b_landing_page_send_email", {"email": email}, mixpanel)
  }

  const setEmailOnState = value => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(value.toLowerCase())) {
      setEmail(value);
      setCanSendEmail(true)
    } else {
      setCanSendEmail(false)
    }
  }

  const setMobileOnState = value => {
    if(value.length == 10 || value.length < 10) {
      setMobile(value);
      setCanSendSms(true)
    } else {
      setCanSendSms(false)
    }
  }


  return (
    <section>
      <div className="container-fluid">
        <div className="row flex-column-reverse flex-md-row">
          <div className="col-md-5 p-0 left left-image-download">
            <BrowserView>
              <img src={LeftDesktopImage} alt="" className="left-image-download-mobile" />
            </BrowserView>
            <MobileView>
              <img src={LeftMobImage} alt="" className="left-image-download-mobile" />
            </MobileView>
          </div>
          {/* right section */}
          <div className="col-md-7 p-0 right d-flex justify-content-center align-items-center">
            <div className="container verified-text-container w-100">
              <div className="verified">
                {
                  isB2c == "true" && isVerified && <p>We're so glad to know you already use Amaha!<br />We will sync your personal and work account</p>
                }
                <div className="mb-5">
                  <Player
                    autoplay
                    src={CheckedLottie}
                    style={{ height: "120px", width: "120px", marginBottom: "24px" }}
                    keepLastFrame
                  />
                  <p className="account-verified">Verification Successful</p>
                </div>
                {isB2c == "false" && <p>{isVerified ? "Verification Successful" : "Account Created!"}</p>}
              </div>

              <div className="next_steps">
                <p className="next-step bold">{isB2c == "true" ? "Here's what you can do now:" : "Here's how you can get started..."}</p>
                <p className="textContent">
                  <b>•</b> Download the Amaha app on your mobile device<br />
                  <b>•</b> Log into the app with your registered work email ID <br />
                  <b>•</b> Get access to self-help tools, therapy sessions & more<br />
                </p>
              </div>
              <p className="textContent">Get Amaha through...</p>
              <div style={{ maxWidth: '480px' }}>
                <div className="tabs">
                  <Tabs>
                    <Tab label="STORE" key="store" eventName="b2b_landing_click_store_tab">
                      <div className="store" id="div1">
                        <a href="https://play.google.com/store/apps/details?id=com.theinnerhour.b2b" onClick={() => logEvent("b2b_landing_play_store_click", {}, mixpanel)}>
                          <img src={PlayStoreImage} className="play" alt="" /></a>
                        <a href="https://apps.apple.com/us/app/innerhour-self-care-therapy/id1323264990" onClick={() => logEvent("b2b_landing_app_store_click", {}, mixpanel)}>
                          <img src={AppStoreImage} alt="" /></a>
                      </div>
                    </Tab>
                    <Tab label="QR CODE" key="qrcode" eventName="b2b_landing_click_qrcode_tab">
                      <div className="qrcode" id="div2">
                        <img src={QrCodeImage} className="qrimg" alt="" />
                        <p className="qrtext">
                        Get access to Amaha by scanning the QR code through your camera.
                        </p>
                      </div>

                    </Tab>
                    <Tab label="EMAIL" key="email" eventName="b2b_landing_click_email_tab">
                      <div className="email form-field" id="div3">
                        <input type="email" className="input-text" placeholder="Enter work e-mail ID" onChange={event => setEmailOnState(event.target.value)}/>
                        <button className="btn" onClick={sendEmailFuncHandle} disabled={!canSendEmail}>SEND</button>
                      </div>
                    </Tab>
                    <Tab label="SMS" key="sms" eventName="b2b_landing_click_sms_tab">
                      <div className="sms form-field" id="div4">
                        <PhoneInput
                          country={countryCode}
                          value={code}
                          inputProps={{ className: 'input-text hide' }}
                          onChange={code => setCode(code)}
                        />
                        <input type="tel" className="input-text" placeholder="Enter mobile number" onChange={event => setMobileOnState(event.target.value)} minLength="10" maxLength="10" />
                        <button className="btn" onClick={sendSmsFuncHandle} disabled={!canSendSms}>SEND</button>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default DownloadComponent;