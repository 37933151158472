import React from 'react'
import TopImage from "../../images/changePasswordPopup.svg"

const ChangePasswordPopUP = props => {
    return (
        <div className="changepassword-popup" style={{ display: props.visible ? "block" : "none" }} onClick={event =>
            event.target.className === "changepassword-popup" && props.toggleModal()
        }>
            <div className="changepassword-popup-content text-center">
                <img src={TopImage} />
                <p className="changepassword-popup-content-heading mt-3">Your Amaha account is already activated</p>
                <p className="changepassword-popup-content-para mt-3">It seems like your account is already activated. Please download the Amaha app to continue.</p>
                <button className="changepassword-popup-button my-4" onClick={props.toggleModal}>CLOSE</button>
            </div>
        </div>
    )
}

export default ChangePasswordPopUP
