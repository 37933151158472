import React from 'react';

const TabButtons = ({ buttons, changeTab, activeTab }) => {

    return (
        <div className="tab-buttons download_app">
            {buttons.map(button => {
                return <a  key={`${button['label']}-key`} className={button['label'] === activeTab ? 'active toggle' : 'toggle deactive'} onClick={() => changeTab(button)}>{button['label']}</a>
            })}
        </div>
    )
}

export default TabButtons;