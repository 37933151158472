import React from 'react';
import TabButtons from './TabButtons';

import { logEvent } from '../../core/analytics';

import { withMixpanel } from 'gatsby-plugin-mixpanel'


class Tabs extends React.Component {
    state = {
        activeTab: this.props.children[0].props.label
    }
    changeTab = (tab) => {

        this.setState({ activeTab: tab['label'] });
        const { mixpanel } = this.props
        logEvent(tab['eventName'], {}, mixpanel)
    };
    render() {

        let content;
        let buttons = [];
        return (
            <div>
                {React.Children.map(this.props.children, child => {
                    buttons.push({ "label": child.props.label, eventName: child.props.eventName })
                    if (child.props.label === this.state.activeTab) content = child.props.children
                })}

                <TabButtons activeTab={this.state.activeTab} buttons={buttons} changeTab={this.changeTab} />
                <div className="tab-content">{content}</div>

            </div>
        );
    }
}


export default withMixpanel()(Tabs);