import React from 'react';

import Layout from "../components/layout"
import Seo from "../components/seo"

import DownloadComponent from '../components/downloads/index';
import AccountApis from '../core/apis/account';

import Popup from '../components/common/Popup';
import ChangePasswordPopUP from '../components/changePassword/ChangePasswordPopUP';

function Download({ location }) {

  const [loading, setLoading] = React.useState(false);

  const [countryCode, setCountryCode] = React.useState("IN");
  const [countryPhoneCode, setCountryPhoneCode] = React.useState("91");

  const [showModal, setShowModal] = React.useState(false);
  const [metadataModal, setMetadataModal] = React.useState({
    "title": "",
    "desc": ""
  });

  const [showChangePasswordModal, setShowChangePasswordModal] = React.useState(false)

  const sendSmsFunc = mobile => {
    setLoading(true);
    AccountApis.sendSms(JSON.stringify({ "mobile": mobile }))
      .then(() => {
        setLoading(false);
        setMetadataModal({ title: "Success", desc: "SMS sent successfully" })
      })
      .then(data => {
        setShowModal(true);
      })
      .catch(error => {
        console.log(error);
      })
  }

  const sendEmailFunc = email => {
    setLoading(true);
    AccountApis.sendEmail(JSON.stringify({ "email": email }))
      .then(data => {
        setMetadataModal({ title: "Success", desc: "An email sent successfully" })
      })
      .then(() => {
        setShowModal(true);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
      })
  }

  React.useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then(response => response.json())
      .then(data => {
        setCountryCode(data['country_code'])
        return data;
      })
      .then(data => {
        setCountryPhoneCode(data['country_calling_code'].substring(1))
      })
      .catch(error => console.error("Not able to fetch country info"))
  })

  React.useEffect(() => {
    if (JSON.parse(localStorage.getItem('showmodal') === "true")) {
      setShowChangePasswordModal(true)
    }
  }, [])

  const params = new URLSearchParams(location.search);
  const isVerified = params.get('verified') || false;
  const isB2c = params.get('wasB2c') || false;

  return (
    <>
      <ChangePasswordPopUP visible={showChangePasswordModal} toggleModal={() => {
        setShowChangePasswordModal(false);
        localStorage.setItem('showmodal', 'false');
      }} />
      <Layout>
        <Seo title="Download" />
        <DownloadComponent
          sendSmsFunc={sendSmsFunc}
          sendEmailFunc={sendEmailFunc}
          countryPhoneCode={countryPhoneCode}
          isVerified={isVerified}
          isB2c={isB2c}
          countryCode={countryCode}
        />
        {showModal && <Popup
          title="Success"
          description={`${metadataModal['desc']}`}
          buttonLabel="ClOSE"
          popupType="success"
          showEmailInput={false}
          handleClick={() => setShowModal(false)}
          togglePopup={() => setShowModal(false)}
        />}
      </Layout>
    </>
  )
}

export default Download;